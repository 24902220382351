import { useAuth0 } from '@auth0/auth0-react';
import NavBar from 'components/Navbar';
import {Text} from 'components/Text';
import React, { useEffect } from 'react';
import {Bar} from './styles';

declare global {
  interface Window {
    ShopifyBuy: any;
  }
}
var client = window.ShopifyBuy.buildClient({
  domain: 'curvebreakers.myshopify.com',
  storefrontAccessToken: '3e8338cbbee6b806f1b1c5c9406d1f0c',
});

var ui = window.ShopifyBuy.UI.init(client);

const LandingPage: React.FC = () => {
  useEffect(() => {
    ui.createComponent('product', {
      id: '7424958234821',
      node: document.getElementById('find-me'),
      options: {
        product: {
          styles: {
            product: {
              '@media (min-width: 601px)': {
                'max-width': 'calc(25% - 20px)',
                'margin-left': '20px',
                'margin-bottom': '50px',
              },
            },
            button: {
              'font-family': 'Lato, sans-serif',
              'font-weight': 'bold',
              ':hover': {
                'background-color': '#e3a033',
              },
              'background-color': '#fcb239',
              ':focus': {
                'background-color': '#e3a033',
              },
              'border-radius': '6px',
            },
          },
          contents: {
            img: false,
            title: false,
            price: false,
          },
          text: {
            button: 'Add to cart',
          },
          googleFonts: ['Lato'],
        },
        productSet: {
          styles: {
            products: {
              '@media (min-width: 601px)': {
                'margin-left': '-20px',
              },
            },
          },
        },
        modalProduct: {
          contents: {
            img: false,
            imgWithCarousel: true,
          },
          styles: {
            product: {
              '@media (min-width: 601px)': {
                'max-width': '100%',
                'margin-left': '0px',
                'margin-bottom': '0px',
              },
            },
            button: {
              'font-family': 'Lato, sans-serif',
              'font-weight': 'bold',
              ':hover': {
                'background-color': '#e3a033',
              },
              'background-color': '#fcb239',
              ':focus': {
                'background-color': '#e3a033',
              },
              'border-radius': '6px',
            },
          },
          googleFonts: ['Lato'],
          text: {
            button: 'Add to cart',
          },
        },
        option: {},
        cart: {
          styles: {
            button: {
              'font-family': 'Lato, sans-serif',
              'font-weight': 'bold',
              ':hover': {
                'background-color': '#e3a033',
              },
              'background-color': '#fcb239',
              ':focus': {
                'background-color': '#e3a033',
              },
              'border-radius': '6px',
            },
          },
          text: {
            total: 'Subtotal',
            button: 'Checkout',
          },
          popup: false,
          googleFonts: ['Lato'],
        },
        toggle: {
          styles: {
            toggle: {
              'font-family': 'Lato, sans-serif',
              'font-weight': 'bold',
              'background-color': '#fcb239',
              ':hover': {
                'background-color': '#e3a033',
              },
              ':focus': {
                'background-color': '#e3a033',
              },
            },
          },
          googleFonts: ['Lato'],
        },
      },
    });

    setTimeout(() => {
      document.querySelector('iframe')!.style.height = '200px';
      document.getElementById('show')!.style.display = 'block';
    }, 2000);
  }, []);

  const { loginWithRedirect } = useAuth0();

  return (
    <div style={{ display: 'none' }} id="show">
      <NavBar/>
      <Bar>
        <Text size={32} color="white" mb={24} mt={24}>
          SAT Practice Packages
        </Text>
      </Bar>
      <div style={{ display: 'flex' }}>
        <p>
          sign up for a free demo course{' '}
          <button
            onClick={() =>
              loginWithRedirect({
                authorizationParams: {
                  screen_hint: 'signup',
                },
              })
            }
          >
            click here
          </button>
        </p>
        <h1>or</h1>
        <div id="find-me">
          <p>some book from shopify store</p>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
