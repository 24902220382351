import React, { useEffect, useState } from "react";
import {
  BodyContainer,
  Body,
  BodyHeader,
  RightRail,
  QuestionHtml,
  QuestionBox,
  EachAnswer,
  Answer,
  Container,
  Header,
  AnswerHtml,
  BottomInfo,
  RightWrong
} from "./styles"; // Adjust the import path as needed
import { Text } from "components/Text"; // Adjust the import path as needed
import { theme } from "components/theme/theme";
import { useKatexContext } from "../../Authenticator";
import { Props } from "./types";

const LETTER_LOOKUP: Record<number, string> = {
  0: "A",
  1: "B",
  2: "C",
  3: "D"
};

const QuestionComponent: React.FC<Props> = ({ data }) => {
  const [writeIn, setWriteIn] = useState<string>("");
  const { renderMath } = useKatexContext();

  useEffect(() => {
    renderMath?.();
  }, [data]);

  const answerSentence = () => {
    if (data.question.Type === "write_in") {
      const correct = data.question?.AnswerChoices?.[0].ChoiceText;
      const userAnswer = data.userAnswer?.WriteInAnswer || "N/A"

      return {
        correct: correct === userAnswer,
        sentence: `Correct answer ${correct}: Your answer: ${userAnswer}`
      };
    }

    const correctAnswer = data?.question?.AnswerChoices?.find(
      ac => ac?.IsCorrectAnswer
    );

    let correct = "";
    let userAnswer = "";
    data?.question?.AnswerChoices?.forEach((a, i) => {
      if (a.AnswerChoiceID === correctAnswer?.AnswerChoiceID) {
        correct = LETTER_LOOKUP[i];
      }
      if (a.AnswerChoiceID === data.userAnswer?.AnswerChoiceID) {
        userAnswer = LETTER_LOOKUP[i];
      }
    });

    return {
      correct: correct === userAnswer,
      sentence: `Correct answer ${correct}: Your answer: ${userAnswer || "N/A"}`
    };
  };

  return (
    <Container>
      <Header>
        <div>
          <Text size={24} bold color={theme.colors.secondary1}>
            {data?.module.Title} | #{data.question.QuestionNumber}
          </Text>
        </div>
      </Header>
      <BodyContainer>
        <Body>
          <BodyHeader>
            {data.module.Category === "english" && (
              <QuestionHtml
                dangerouslySetInnerHTML={{
                  __html: data.question.Passage || ""
                }}
              ></QuestionHtml>
            )}
            {data.module.Category === "math" && (
              <QuestionHtml
                data-testid="react-katex"
                dangerouslySetInnerHTML={{
                  __html: data.question.Question || ""
                }}
              ></QuestionHtml>
            )}
          </BodyHeader>
        </Body>
        <RightRail>
          {data.module.Category === "english" && (
            <QuestionHtml
              dangerouslySetInnerHTML={{
                __html: data.question.Question || ""
              }}
            ></QuestionHtml>
          )}
          <QuestionBox>
            {data.question.AnswerChoices?.map((a, i) => {
              if (data.question.Type === "write_in") {
                return (
                  <EachAnswer key={i}>
                    <input
                      type="text"
                      value={writeIn}
                      placeholder="Write answer here"
                      name="write_in"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setWriteIn(e.target.value)
                      }
                    />
                  </EachAnswer>
                );
              }
              return (
                <EachAnswer key={a.AnswerChoiceID + a.ChoiceText}>
                  <Answer>
                    {i === 0 && "A)  "}
                    {i === 1 && "B)  "}
                    {i === 2 && "C)  "}
                    {i === 3 && "D)  "}
                    <AnswerHtml
                      dangerouslySetInnerHTML={{
                        __html: a.ChoiceText || ""
                      }}
                    ></AnswerHtml>
                  </Answer>
                </EachAnswer>
              );
            })}
          </QuestionBox>
        </RightRail>
      </BodyContainer>
      <BottomInfo>
        {data.userAnswer && (
          <RightWrong
            size={14}
            background={
              data?.userAnswer
                ? answerSentence().correct
                  ? "green"
                  : "red"
                : "#FBB23A"
            }
          >
            {answerSentence().sentence}
          </RightWrong>
        )}
        {data?.question.KeyExplanation && (
          <>
            <Text size={24}>Explanation</Text>
            <div
              dangerouslySetInnerHTML={{
                __html: data?.question.KeyExplanation || ""
              }}
            ></div>
          </>
        )}
        {data.question.DistractorExplanation && (
          <>
            <Text size={24}>Distractor Explanation</Text>
            <div
              dangerouslySetInnerHTML={{
                __html: data?.question.DistractorExplanation || ""
              }}
            ></div>
          </>
        )}
      </BottomInfo>
    </Container>
  );
};

export default QuestionComponent;
