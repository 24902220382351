import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../Authenticator";
import { fetcherWithToken, updateWithToken } from "../../hooks/use-fetch-data";
import { GRAD_YEARS, Root, Test } from "./types";
import {
  Bubble,
  Container,
  Top,
  Bottom,
  Footer,
  UserInfoContainer
} from "./styles";
import useSWR from "swr";
import { Text } from "components/Text";
import { Modal } from "components/Modal";
import { Input } from "components/Input";
import { Button } from "components/Button";
import toast from "react-hot-toast";
import { FaRegCheckCircle } from "react-icons/fa";
import { GrInProgress } from "react-icons/gr";
import { GiFinishLine } from "react-icons/gi";
import { DivComponent } from "components";

const Dashboard = () => {
  const userCtx = useUserContext();
  const navigate = useNavigate();
  const [displayUserModal, setDisplayUserModal] = useState(false);
  const [user, setUser] = useState({
    UserID: userCtx.user.UserID,
    FirstName: "",
    LastName: "",
    Email: userCtx.user.Email
  });

  const { data, error } = useSWR<Root[]>(
    `/api/v1/auth/dashboard`,
    (url: string) => fetcherWithToken<Root[]>(url, userCtx.token)
  );
  if (error) {
    console.error(error);
  }

  useEffect(() => {
    if (!userCtx.user.FirstName) {
      setDisplayUserModal(true);
    }
  }, []);

  const updateUser = async () => {
    const res = await updateWithToken(
      `/api/v1/auth/admin/update-user`,
      userCtx.token,
      user
    );

    if (res.status === 201) {
      setDisplayUserModal(false);
      toast.success("Updated Profile", {
        position: "bottom-left",
        icon: <FaRegCheckCircle size={18} color="#154471" />
      });
    }
  };

  const getModuleID = (test: Test) => {
    const mod = test.Modules.find(mod => {
      if (!mod.UserModule.Finished) {
        return mod;
      }
    });

    return mod?.ModuleID;
  };

  const activeOrPast = () => {
    const organizedClasses = data?.reduce(
      (acc, currentClass) => {
        const isPastClass = currentClass.Tests.every(test =>
          test.Modules.every(module => module.UserModule.Finished)
        );
        if (isPastClass) {
          acc.past.push(currentClass);
        } else {
          acc.active.push(currentClass);
        }
        return acc;
      },
      { active: [] as Root[], past: [] as Root[] }
    );

    return organizedClasses;
  };

  const splitClasses = activeOrPast();

  const viewReportOrstartTest = (test: Test, classID: number) => {
    const finished = test.Modules.every(m => m.UserModule.Finished);

    if (finished) {
      navigate(`/class/${classID}/test/${test.TestID}/report`);
      return;
    }

    if (test.SavedProgress) {
      const ending =
        test.SavedProgress.QuestionNumber === 0
          ? "/"
          : `/question/${test.SavedProgress.QuestionNumber}`;
      navigate(
        `/class/${classID}/test/${test.TestID}/module/${test.SavedProgress.ModuleID}${ending}`
      );
      return;
    } else {
      navigate(
        `/class/${classID}/test/${test.TestID}/module/${getModuleID(test)}`
      );
      return;
    }
  };

  const finished = (test: Test) => {
    return test.Modules.every(module => module.UserModule.Finished);
  };


  return (
    <>
      <Modal
        open={displayUserModal}
        onClose={() => setDisplayUserModal(false)}
        small
      >
        <UserInfoContainer>
          <Text size={30} center bold mb={16}>
            Tell us more about yourself...
          </Text>
          <Input
            withLabelText={"First Name"}
            width={250}
            name="FirstName"
            value={user.FirstName}
            onChange={e => setUser({ ...user, FirstName: e.target.value })}
          />
          <Input
            withLabelText={"Last Name"}
            width={250}
            name="LastName"
            value={user.LastName}
            onChange={e => setUser({ ...user, LastName: e.target.value })}
          />
          <label>Graduation Year</label>
          <select
          // value={selectedModule?.ModuleID}
          // onChange={handleChange}
          >
            {GRAD_YEARS?.map(g => {
              return <option value={g}>{g}</option>;
            })}
          </select>
          <Button onClick={() => updateUser()} text="Save" />
        </UserInfoContainer>
      </Modal>
      <Container>
        <Text size={48} bold mb={24}>
          Your Classes
        </Text>
        {splitClasses && splitClasses.active.length > 0 && (
          <Text size={18} mb={12}>
            Active
          </Text>
        )}
        {splitClasses?.active.map(userClass => (
          <Bubble key={userClass.ClassID}>
            <Top>
              <Text bold size={18}>
                {userClass.Name}
              </Text>
            </Top>
            <Bottom>
              {userClass.Tests.map(t => (
                <DivComponent
                  onClick={() => viewReportOrstartTest(t, userClass.ClassID)}
                  mb={16}
                >
                  <Text size={18} hover>
                    {t.Title}
                  </Text>
                  {t.SavedProgress && (
                    <DivComponent justifyContent goRight>
                      {finished(t) ? (
                        <>
                          <GiFinishLine size={20} />
                          <Text size={18} ml={8}>
                            Finished
                          </Text>
                        </>
                      ) : (
                        <>
                          <GrInProgress size={20} />
                          <Text size={18} ml={8}>
                            In Progress
                          </Text>
                        </>
                      )}
                    </DivComponent>
                  )}
                </DivComponent>
              ))}
            </Bottom>
          </Bubble>
        ))}
        {splitClasses && splitClasses.past.length > 0 && (
          <Text size={18} mb={12}>
            Past
          </Text>
        )}
        {splitClasses?.past.map(userClass => (
          <Bubble key={userClass.ClassID}>
            <Top>
              <Text bold size={18}>
                {userClass.Name}
              </Text>
            </Top>
            <Bottom>
              {userClass.Tests.map(t => (
                <DivComponent
                  onClick={() => viewReportOrstartTest(t, userClass.ClassID)}
                  mb={16}
                >
                  <Text size={18} hover>
                    {t.Title}
                  </Text>
                </DivComponent>
              ))}
            </Bottom>
          </Bubble>
        ))}
      </Container>
    </>
  );
};

export default Dashboard;
