import React, { useEffect, useState } from "react";
import {
  SectionContainer,
  ClassHeader,
  StepContainer,
  UpdateClassContainer
} from "./styles";
import { Button } from "components/Button";
import { Class, School, Student, Teacher, Test } from "./types";
import { postWithToken, updateWithToken } from "hooks/use-fetch-data";
import { useUserContext } from "Authenticator";
import { ColumnDef } from "@tanstack/react-table";
import {
  GenericTable,
  fuzzySort,
  Text,
  Input,
  Step,
  Stepper,
  Modal,
  UserTable,
  DivComponent
} from "components";

const steps = [
  {
    label: "Class Name",
    step: 1
  },
  {
    label: "Assign Teacher",
    step: 2
  },
  {
    label: "Assign Students",
    step: 3
  },
  {
    label: "Assign Tests",
    step: 4
  }
];

const dataColumns: ColumnDef<any, any>[] = [
  {
    accessorFn: row => row.Title, // Accessor function for the title
    id: "TestID",
    header: "Title", // Header for the column
    cell: info => info.getValue(), // Cell renderer
    sortingFn: fuzzySort // Sorting function
  }
];

export const ClassComponent: React.FC<{
  classes?: Class[];
  students?: Student[];
  teachers?: Teacher[];
  tests?: Test[];
  selectedSchool?: School;
  refreshDashboard: (schoolSlug?: School) => Promise<void>;
}> = ({
  classes,
  students,
  tests,
  selectedSchool,
  refreshDashboard,
  teachers
}) => {
  const userCtx = useUserContext();

  const [createClass, setCreateClass] = useState(false);
  const [studentModal, setStudentModal] = useState(false);
  const [testModal, setTestModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState<Class>();
  const [testsToAdd, setTestsToAdd] = useState<number[]>([]);

  const [formData, setFormData] = useState<{
    className: string;
    students: number[];
    teachers: number[];
    tests: number[];
    school?: number;
  }>({
    className: "",
    students: [],
    teachers: [],
    tests: [],
    school: undefined
  });

  useEffect(() => {
    setFormData({ ...formData, school: selectedSchool?.SchoolID });
  }, [selectedSchool]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, className: e.target.value });
  };

  const updateForm = (
    e: React.ChangeEvent<HTMLInputElement>,
    role: "students" | "teachers"
  ) => {
    const found = formData[role].find(s => s === Number(e.target.value));

    if (found) {
      const newState = formData[role].filter(s => s !== Number(e.target.value));
      setFormData({
        ...formData,
        [role]: newState
      });
    } else {
      if (role === "teachers") {
        setFormData({
          ...formData,
          teachers: [Number(e.target.value)]
        });
      } else {
        setFormData({
          ...formData,
          [role]: [...formData[role], Number(e.target.value)]
        });
      }
    }
  };

  const updateTests = (e: React.ChangeEvent<HTMLInputElement>) => {
    const found = formData.tests.find(t => t === Number(e.target.value));
    if (found) {
      const newState = formData.tests.filter(t => t !== Number(e.target.value));
      setFormData({
        ...formData,
        tests: newState
      });
    } else {
      setFormData({
        ...formData,
        tests: [...formData.tests, Number(e.target.value)]
      });
    }
  };

  const isDisabled =
    formData.className.length === 0 ||
    formData.students.length === 0 ||
    formData.tests.length === 0;

  const saveClass = async () => {
    const res = await postWithToken(
      `/api/v1/auth/admin/dashboard/save-class`,
      userCtx.token,
      {
        ...formData
      }
    );

    if (res.status === 201) {
      refreshDashboard(selectedSchool);
      setCreateClass(false);
    }
  };

  const [studentsToAdd, setStudentsToAdd] = useState<number[]>([]);

  const addToStudents = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const studentNumber = Number(value);

    const isExisting = studentsToAdd.includes(studentNumber);

    if (isExisting) {
      const updatedStudents = studentsToAdd.filter(
        num => num !== studentNumber
      );
      setStudentsToAdd(updatedStudents);
    } else {
      setStudentsToAdd([...studentsToAdd, studentNumber]);
    }
  };

  const addStudents = async () => {
    const res = await postWithToken(
      "/api/v1/auth/admin/dashboard/save-user-to-class",
      userCtx.token,
      {
        studentsToAdd,
        classID: selectedClass?.ClassID
      }
    );

    if (res.status === 201) {
      refreshDashboard(selectedSchool);
      setStudentModal(false);
      setStudentsToAdd([]);
    }
  };

  const addTestsToClass = async () => {
    await updateWithToken(
      `/api/v1/auth/admin/dashboard/update-class-tests`,
      userCtx.token,
      {
        tests: testsToAdd,
        classID: selectedClass?.ClassID
      }
    );

    setTestModal(false);
    refreshDashboard();
  };

  const toggleTest = (testId: number) => {
    setTestsToAdd(prevTests => {
      // Check if the testId already exists in the array
      if (prevTests.includes(testId)) {
        // Remove the testId if it already exists
        return prevTests.filter(id => id !== testId);
      } else {
        // Add the testId if it doesn't exist
        return [...prevTests, testId];
      }
    });
  };

  return (
    <>
      <Modal open={studentModal} onClose={() => setStudentModal(false)}>
        <Text size={32} center>
          Add Students to {selectedClass?.Name}
        </Text>
        <UpdateClassContainer>
          <UserTable
            students={students}
            checkBox
            checkedStudents={studentsToAdd}
            checkBoxClick={addToStudents}
          />
        </UpdateClassContainer>
        <Button text="Add Students" onClick={addStudents} floatRight />
      </Modal>
      <Modal open={testModal} onClose={() => setTestModal(false)}>
        <Text size={32} center>
          Add Tests to {selectedClass?.Name}
        </Text>
        <UpdateClassContainer>
          <GenericTable
            data={tests!}
            columns={dataColumns}
            checkBox
            checkedItems={testsToAdd}
            checkBoxClick={id => toggleTest(id)}
            idKey="TestID"
          />
        </UpdateClassContainer>
        <Button text="Submit" onClick={addTestsToClass} floatRight />
      </Modal>
      <SectionContainer>
        {createClass && (
          <>
            <Stepper steps={steps}>
              <Step s={1}>
                <StepContainer>
                  <Text size={14} bold mb={12}>
                    Class Name
                  </Text>
                  <Input placeholder="Class Name..." onChange={handleChange} />
                </StepContainer>
              </Step>
              <Step s={2}>
                <StepContainer>
                  <Text size={14} bold mb={12}>
                    Assign Teachers To {formData.className}
                  </Text>
                  {teachers?.map(s => {
                    return (
                      <div>
                        <input
                          type="checkbox"
                          onChange={e => updateForm(e, "teachers")}
                          value={s.UserID}
                          checked={formData.teachers.includes(s.UserID)}
                        />
                        <label>
                          {s.FirstName} {s.LastName} - {s.Email}
                        </label>
                      </div>
                    );
                  })}
                </StepContainer>
              </Step>
              <Step s={3}>
                <StepContainer>
                  <Text size={14} bold mb={12}>
                    Assign Students To Class
                  </Text>
                  {students?.map(s => {
                    return (
                      <div>
                        <input
                          type="checkbox"
                          onChange={e => updateForm(e, "students")}
                          value={s.UserID}
                          checked={formData.students.includes(s.UserID)}
                        />
                        <label>
                          {s.FirstName} {s.LastName} - {s.Email}
                        </label>
                      </div>
                    );
                  })}
                </StepContainer>
              </Step>
              <Step s={4} next={saveClass}>
                <StepContainer>
                  <Text size={14} bold mb={12}>
                    Assing Test to Class
                  </Text>
                  {tests?.map(t => {
                    return (
                      <div>
                        <input
                          type="checkbox"
                          onChange={updateTests}
                          value={t.TestID}
                          checked={formData.tests.includes(t.TestID)}
                        />
                        <label>{t.Title}</label>
                      </div>
                    );
                  })}
                </StepContainer>
              </Step>
            </Stepper>
          </>
        )}
        {!createClass && (
          <>
            <ClassHeader>
              <Text size={24}>Class List for {selectedSchool?.Name}</Text>
              <Button
                text="Create a Class"
                onClick={() => setCreateClass(true)}
              />
            </ClassHeader>
            {classes?.map(c => {
              return (
                <div style={{ marginBottom: "30px" }}>
                  <Text mb={12} size={12}>
                    Class: {c.Name}
                  </Text>
                  <Text mb={12} size={12}>
                    Teacher:{" "}
                    {c?.Teachers?.map(t => `${t.FirstName} ${t.LastName}`)}
                  </Text>
                  <DivComponent>
                    <Text size={12} mr={5}>
                      Tests:
                    </Text>
                    {c.Tests.map((t, index) => (
                      <React.Fragment key={index}>
                        <Text mr={4}>{t.Title}</Text>
                        {index < c.Tests.length - 1 && (
                          <Text size={12} mr={4}>
                            and
                          </Text>
                        )}
                      </React.Fragment>
                    ))}
                  </DivComponent>
                  <table>
                    <thead>
                      <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    {c?.Students?.map(s => {
                      return (
                        <tr>
                          <td>{s.FirstName}</td>
                          <td>{s.LastName}</td>
                          <td>{s.Email}</td>
                        </tr>
                      );
                    })}
                  </table>
                  <DivComponent>
                    <Button
                      onClick={() => {
                        setSelectedClass(c);
                        setStudentModal(true);
                      }}
                      text={`Add students`}
                      floatRight
                    />
                    <Button
                      onClick={() => {
                        setSelectedClass(c);
                        setTestModal(true);
                      }}
                      ml={10}
                      text={`Add Tests`}
                    />
                  </DivComponent>
                </div>
              );
            })}
          </>
        )}
      </SectionContainer>
    </>
  );
};
