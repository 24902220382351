import React, { useEffect } from "react";
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  sortingFns,
  getSortedRowModel,
  flexRender,
  FilterFn,
  SortingFn,
  ColumnDef,
  getPaginationRowModel,
  Row
} from "@tanstack/react-table";
import {
  RankingInfo,
  rankItem,
  compareItems
} from "@tanstack/match-sorter-utils";
import { Container } from "../UserTable/styles";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export function DebouncedInput<T>({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      style={{ padding: 10 }}
      {...props}
      value={value}
      onChange={e => setValue(e.target.value)}
    />
  );
}

export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta({
    itemRank
  });
  return itemRank.passed;
};

interface GenericTableProps<T> {
  data: T[];
  columns: ColumnDef<T, any>[];
  checkBox?: boolean;
  checkBoxClick?: (id: any) => void;
  checkedItems?: any[];
  idKey?: keyof T; // This is the key used to identify the item, e.g., TestID or something else
}

export const GenericTable = <T extends { id: string | number }>({
  data = [],
  columns,
  checkBox,
  checkBoxClick,
  checkedItems,
  idKey = "id"
}: GenericTableProps<T>) => {
  const [tableData, setTableData] = React.useState(() => [...data]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  useEffect(() => {
    setTableData(data);
  }, [data]);

  // Add checkbox column if `checkBox` is enabled
  const enhancedColumns = React.useMemo(() => {
    if (checkBox) {
      return [
        {
          id: "checkbox",
          header: "Select",
          cell: ({ row }: { row: Row<T> }) => {
            const rowId = row.original[idKey];
            return (
              <input
                type="checkbox"
                // @ts-ignore
                checked={checkedItems?.includes(row.original[idKey])}
                // @ts-ignore
                onChange={() => checkBoxClick?.(row.original[idKey])}
              />
            );
          }
        },
        ...columns
      ];
    }
    return columns;
  }, [checkBox, columns, checkedItems, checkBoxClick, idKey]);

  const table = useReactTable({
    data: tableData,
    columns: enhancedColumns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      columnFilters,
      globalFilter
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getPaginationRowModel: getPaginationRowModel()
  });

  return (
    <Container>
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </button>
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </button>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </button>
        <button
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </button>
      </div>
      <div>
        <span style={{ marginRight: "10px" }}>
          <strong>
            Page {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </Container>
  );
};
