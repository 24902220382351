import styled from "styled-components";
import { Text } from "components/Text"; // Adjust the import path as needed

export const Container = styled.div`
  margin: 24px auto 25px auto;
  border-radius: 10px;
  max-width: 1280px;
  background: ${({ theme }) => theme.colors.secondary1};
  border: ${({ theme }) => `2px solid ${theme.colors.primary2}`};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 24px;
  background: ${({ theme }) => theme.colors.primary2};
  color: ${({ theme }) => theme.colors.secondary1};
  margin-bottom: 10px;
  min-height: 50x;
`;

export const BodyContainer = styled.div`
  display: flex;
  min-height: 450px;
`;

export const Body = styled.div`
  flex: 2;
  padding: 24px;
  margin-bottom: 20px;
`;

export const BodyHeader = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin-left: 10px;
  }
`;

export const RightRail = styled.div`
  flex: 1;
  padding: 24px;
`;

export const QuestionBox = styled.div`
  background: ${({ theme }) => theme.colors.grey1};
  color: ${({ theme }) => theme.colors.primary2};
  padding: 8px;
  margin-bottom: 24px;
  border-radius: 10px;

  input {
    margin-bottom: 10px;
    margin: auto;
    height: 50px;
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 3px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }

  input[type="text"]:focus {
    border: ${({ theme }) => `3px solid ${theme.colors.primary1}`};
  }
`;

export const EachAnswer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid black;
`;

export const Answer = styled.span<{ crossed?: boolean; selected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 20px 0;
  size: 14px;
  color: black;
  margin-left: 10px;
  width: 100%;

  &:hover {
    color: ${({ theme }) => theme.colors.primary1};
    cursor: pointer;
  }

  ${({ crossed }) =>
    crossed &&
    `
    text-decoration: line-through;
    `}

  ${({ selected, theme }) =>
    selected &&
    `
    font-weight: bold;
    color: ${theme.colors.primary1};
    `}
`;

export const QuestionHtml = styled.div`
  p {
    font-size: 24px;
  }

  img {
    height: auto;
    max-width: 750px;
  }

  table,
  th,
  td {
    border: 1px solid;
  }
`;

export const AnswerHtml = styled.div`
  margin-left: 10px;
  table,
  th,
  td {
    border: 1px solid;
  }
  p {
    font-size: 12px;
  }
`;

export const BottomInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
`;

export const RightWrong = styled(Text)`
  width: fit-content;
  align-self: flex-end;
`
