import React, {useState} from 'react';
import { Props } from './types';
import { ButtonComponent } from './styles';

export const Button: React.FC<Props> = ({
  text,
  fullWidth,
  mb,
  mt,
  mr,
  ml,
  onClick,
  color,
  invisible,
  disabled = false,
  floatRight = false,
  children,
  className,
}) => {

  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = async () => {
    setSubmitting(true)

    try {
      await onClick()
    } finally {
      setSubmitting(false)
    }

  }
  return (
    <ButtonComponent
      className={className}
      onClick={() => handleSubmit()}
      mb={mb}
      mt={mt}
      mr={mr}
      ml={ml}
      fullWidth={fullWidth}
      color={color}
      invisible={invisible}
      disabled={disabled || submitting}
      floatRight={floatRight}
    >
      {text}
      {children}
    </ButtonComponent>
  );
};
