import { styled } from "styled-components";

export const MainContainer = styled.div`
  margin: 0 auto;
  padding: 0 16px;
`;

export const StepContainer = styled.div<{ width: string }>`
  display: flex;
  justify-content: space-between;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.primary2};
    opacity: 0.5;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  &:after {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.colors.primary2};
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

export const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

export const StepStyle = styled.div<{ step: string }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid
    ${({ step, theme }) =>
      step === "completed" ? `${theme.colors.primary2}` : "#849AB1"};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepCount = styled.span`
  font-size: 15px;
  color: #849ab1;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
`;

export const StepLabel = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.primary2};
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 100px;
`;

export const ButtonStyle = styled.button`
  border-radius: 4px;
  border: 0;
  background: #4a154b;
  color: #ffffff;
  cursor: pointer;
  padding: 8px;
  width: 90px;
  &:active {
    transform: scale(0.98);
  }
  &:disabled {
    background: #f3e7f3;
    color: #000000;
    cursor: not-allowed;
  }
`;

export const CheckMark = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary2};
  transform: scaleX(-1) rotate(-46deg);
`;

