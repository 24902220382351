import React, { useEffect } from "react";
import { Student } from "Pages/Admin/Admin/types";
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  getPaginationRowModel
} from "@tanstack/react-table";
import {
  RankingInfo,
  rankItem,
  compareItems
} from "@tanstack/match-sorter-utils";
import { Container } from "./styles";

export const DEFAULT_USER: Student = {
  UserID: 0,
  Email: "",
  FirstName: "",
  LastName: "",
  Role: ""
};

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      style={{ padding: 10 }}
      {...props}
      value={value}
      onChange={e => setValue(e.target.value)}
    />
  );
}

export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const UserTable: React.FC<{
  students?: Student[];
  checkBox?: boolean;
  checkBoxClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkedStudents?: number[];
}> = ({ students = [], checkBox, checkBoxClick, checkedStudents }) => {
  const [data, setData] = React.useState(() => [...students]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  useEffect(() => {
    setData(students);
  }, [students]);
  const [globalFilter, setGlobalFilter] = React.useState("");

  const columns = React.useMemo<ColumnDef<Student, any>[]>(
    () => [
      {
        header: "Student List",
        colSpan: 2,
        rowSpan: 2,
        columns: [
          {
            accessorFn: row => row.LastName,
            id: "LastName",
            header: "Last Name",
            cell: info => info.getValue(),
            filterFn: "fuzzy",
            sortingFn: fuzzySort
          },
          {
            accessorFn: row => row.FirstName,
            id: "FirstName",
            header: "First Name",
            cell: info => info.getValue(),
            filterFn: "fuzzy",
            sortingFn: fuzzySort
          },
          {
            accessorFn: row => row.Email,
            id: "email",
            header: "Email",
            cell: info => info.getValue(),
            filterFn: "fuzzy",
            sortingFn: fuzzySort
          },
          checkBox && {
            header: "Add",
            cell: cell => (
              <input
                type="checkbox"
                value={cell.row.original.UserID}
                checked={checkedStudents?.includes(cell.row.original.UserID)}
                onChange={e => checkBoxClick && checkBoxClick(e)}
              />
            )
          }
        ]
      }
    ],
    [checkBox, checkBoxClick, checkedStudents]
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      columnFilters,
      globalFilter
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getPaginationRowModel: getPaginationRowModel()
  });
  return (
    <Container>
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </button>
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </button>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </button>
        <button
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </button>
      </div>
      <div>
        <span style={{ marginRight: "10px" }}>
          <strong>
            Page {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </Container>
  );
};
