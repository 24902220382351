import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  min-height: 100vh;


  table,
  th,
  td {
    border: ${({ theme }) => `1px solid ${theme.colors.primary2}`};
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    text-align: center;
    padding: 12px;
  }
`;

export const Section = styled.div`
  margin: auto;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.secondary1};
  margin: 24px;
  padding: 48px;
`
export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Stats = styled.div`
  display: flex;
  padding: 24px 48px;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.primary1};
  border-radius: 10px;
  margin-bottom: 24px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &:not(:last-of-type) {
      border-right: ${({ theme }) => `2px solid ${theme.colors.primary2}`};
    }
  }
`;

export const TD = styled.td<{ correct: boolean, blank: boolean }>`
  color: red;
  font-weight: bold;

  ${({ correct }) =>
    correct &&
    `
      color:  green;
    `}

  ${({  blank }) =>
    blank &&
    `
      color: initial;;
    `}
`;

export const ScoreCard = styled.div`
  background: ${({ theme }) => theme.colors.primary1};
  border-radius: 10px;
  width: 325px;
  padding-top: 24px;
  margin-bottom: 12px;
  margin: 0 auto 12px auto;
  border: 0.5px solid #e3e3e3;
`;

export const ScoreCardInfo = styled.div`
  background: white;
  border-radius: 10px;
  border: .5px solid #E3E3E3;
  padding: 24px 12px 24px 12px;

  span {
    display:block;
    width: 275px;
    height: 1px;
    background: #e3e3e3;
    margin: 0px auto 24px auto;
  }

`;

  export const ScoreCategory = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    div {
      display: flex;
      flex-direction: column;
    }

`
