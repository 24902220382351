import { useEffect, useState } from "react";

interface Timer {
  initialSeconds?: number;
  onFinish?: () => void;
}

export const useTimer = ({ initialSeconds, onFinish }: Timer) => {
  const [secondsLeft, setSecondsLeft] = useState<number | undefined>(
    initialSeconds
  );
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (!isActive) return;

    if (secondsLeft === 0) {
      onFinish?.();
      setIsActive(false);
      return; // If timer is 0, no need to proceed further
    }

    const intervalId = setInterval(() => {
      setSecondsLeft(prevSeconds => (prevSeconds || 0) - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [isActive, secondsLeft, onFinish]);

  const start = (time: number) => {
    setSecondsLeft(time);
    setIsActive(true);
  };

  const minutes = Math.floor((secondsLeft || 0) / 60);
  const seconds = (secondsLeft || 0) % 60;

  return { minutes, seconds, start, hasStarted: isActive };
};
