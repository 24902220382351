import styled from "styled-components";

export const Container = styled.div`
  max-width: 1180px;
  margin: auto;
  padding: 24px 24px;
`;

export const Bubble = styled.div`
  background: ${({ theme }) => theme.colors.secondary1};
  border-radius: 10px;
  width: 400px;
`;

export const Top = styled.div`
  background: ${({ theme }) => theme.colors.primary1};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 5px 12px;
  margin-bottom: 12px;
`;

export const Bottom = styled.div`
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 5px 12px;
  margin-bottom: 24px;
`;

export const Footer = styled.div<{ mt?: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primary2};
  height: 150px;
  width: 100%;
  z-index: -1;
  margin-top: 250px;

  ${({ mt }) =>
    mt &&
    `
    margin-top: ${mt};
  `}
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;

  button {
    width: 150px;
    margin-top: auto;
  }

  select {
    height: 50px;
    padding: 12px;
    margin: 8px 0px;
    width: 250px;
  }
`;
