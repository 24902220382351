import React, { useState, ReactNode } from "react";
import styled from "styled-components";
import { HiDotsVertical } from "react-icons/hi";

interface DropdownProps {
  items: ReactNode[];
}

interface DropdownMenuProps {
  isOpen: boolean;
}

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const DropdownToggle = styled.div`
  cursor: pointer;
`;

const DropdownMenu = styled.div<DropdownMenuProps>`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  top: 100%;
  right: 5px;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 200px;
  border-radius: 4px;
  overflow: hidden;
`;

const DropdownItem = styled.div<{ highlighted: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: ${({ highlighted }) => (highlighted ? "#f0f0f0" : "white")};

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Dropdown: React.FC<DropdownProps> = ({ items }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <DropdownContainer>
      <DropdownToggle onClick={toggleDropdown}>
        <HiDotsVertical size={30} color={"white"} />
      </DropdownToggle>
      <DropdownMenu isOpen={isOpen}>
        {items.map((item, index) => (
          <DropdownItem key={index} highlighted={false}>
            {item}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default Dropdown;
