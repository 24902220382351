import styled from "styled-components";

export const Container = styled.div<{
  width?: number;
}>`
  input[type="text"],
  select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  input[type="submit"] {
    width: 100%;
    background-color: #4caf50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  input[type="submit"]:hover {
    background-color: #45a049;
  }

  input {
    &:focus {
      outline: ${({ theme }) => `1px solid ${theme.colors.primary1}`};
      border: ${({ theme }) => `1px solid ${theme.colors.primary1}`};
    }
  }

  ${({ width }) =>
    width &&
    `
    width: ${width}px;
    `}

`;
