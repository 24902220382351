import React, { useEffect, useState } from "react";
import { useUserContext } from "Authenticator";
import { fetcherWithToken } from "hooks/use-fetch-data";
import { Root, Test, Module } from "./types";
import { Button, DivComponent, QuestionComponent, Text } from "components";
import {theme} from "components/theme/theme";

export const PreviewTest: React.FC = () => {
  const userCtx = useUserContext();
  const [tests, setTests] = useState<Test[]>([]);
  const [selectedTest, setSelectedTest] = useState<Test | null>(null);
  const [selectedModule, setSelectedModule] = useState<Module | null>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);

  useEffect(() => {
    const fetchTests = async () => {
      const resp = await fetcherWithToken<Root>(
        "/api/v1/auth/admin/tests",
        userCtx.token
      );
      setTests(resp.tests);
    };

    fetchTests();
  }, [userCtx.token]);

  const handleTestChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const test = tests.find(t => t.TestID === Number(e.target.value));
    setSelectedTest(test || null);
    setSelectedModule(null);
    setCurrentQuestionIndex(0);
  };

  const handleModuleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const module = selectedTest?.Modules?.find(
      m => m.ModuleID === Number(e.target.value)
    );
    setSelectedModule(module || null);
    setCurrentQuestionIndex(0);
  };

  const handleNextQuestion = () => {
    if (selectedModule && selectedModule.Questions) {
      setCurrentQuestionIndex(prevIndex =>
        prevIndex < selectedModule.Questions!.length - 1
          ? prevIndex + 1
          : prevIndex
      );
    }
  };

  const handlePreviousQuestion = () => {
    if (selectedModule && selectedModule.Questions) {
      setCurrentQuestionIndex(prevIndex =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }
  };

  return (
    <>
      <DivComponent alignItems justifyContent mb={20} mt={20}>
        <div>
          <Text size={20} mr={20}>Select a Test to Preview</Text>
          <select
            onChange={handleTestChange}
            value={selectedTest?.TestID || ""}
          >
            <option value="" disabled>
              Select a test
            </option>
            {tests.map(test => (
              <option key={test.TestID} value={test.TestID}>
                {test.Title}
              </option>
            ))}
          </select>
        </div>
        <div>
          {selectedTest && (
            <>
              <Text size={20}>Select a Module to Preview</Text>
              <select
                onChange={handleModuleChange}
                value={selectedModule?.ModuleID || ""}
              >
                <option value="" disabled>
                  Select a module
                </option>
                {selectedTest.Modules?.map(module => (
                  <option key={module.ModuleID} value={module.ModuleID}>
                    {module.Title} | {module?.Questions?.length} Questions
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      </DivComponent>
      {selectedModule && selectedModule.Questions && (
        <>
          <QuestionComponent
            data={{
              question: selectedModule.Questions[currentQuestionIndex],
              module: selectedModule
            }}
          />
          <DivComponent alignItems mb={20} justifyContent>
            <Button text="Previous Question"
              color={theme.colors.primary1}
              onClick={handlePreviousQuestion}
              disabled={currentQuestionIndex === 0}
              mr={15}
            />
            <Button text="Next Question"
              color={theme.colors.primary2}
              onClick={handleNextQuestion}
              disabled={
                currentQuestionIndex >= selectedModule.Questions.length - 1
              }
            />
          </DivComponent>
        </>
      )}
    </>
  );
};
