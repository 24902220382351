import React from "react";
import { Overlay, ModalContainer } from "./styles";
import { useOutsideClick } from "hooks/clickoutside";

interface Props {
  onClose: () => void;
  children: React.ReactNode;
  open: boolean;
  small?: boolean;
  medium?: boolean;
}

export const Modal: React.FC<Props> = ({
  onClose,
  children,
  open,
  medium,
  small
}) => {
  const ref = useOutsideClick(() => {
    onClose();
  });

  return (
    <Overlay open={open}>
      <ModalContainer open={open} ref={ref} small={small} medium={medium}>
        <button onClick={() => onClose()}>X</button>
        {children}
      </ModalContainer>
    </Overlay>
  );
};
