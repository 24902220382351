import React, { useState, useEffect, useRef } from 'react';
import { Nav, Logo, Circle, Dropdown, Right, MaxWidth } from './styles';
import logo from './curvebreakers.png';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Text } from 'components/Text';
import { BsFillPersonFill } from 'react-icons/bs';
import {useUserContext} from 'Authenticator';

const NavBar = () => {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth0();

  const { user } = useUserContext()

  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    /* Used to close the dropdown if the user clicks outside of it */
    const dropdownCloser = (event: Event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', dropdownCloser);

    return () => document.removeEventListener('mousedown', dropdownCloser);
  }, [open]);

  const logoutUser = () => {
     logout({
      logoutParams: {
        returnTo: `${window.location.origin}/`,
      },
    });
  }

  return (
    <MaxWidth>
      <Nav onClick={() => navigate('/')}>
        <Logo src={logo} />
        {isAuthenticated && (
          <Right>
            <Text bold>
            {user?.FirstName ? `${user?.FirstName} ${user?.LastName}` : `${user?.Email}`}
            </Text>
            <Circle onClick={() => setOpen(!open)}>
              <BsFillPersonFill />
            </Circle>
          </Right>
        )}
      </Nav>
      {open && (
        <>
          <Dropdown ref={dropdownRef}>
            <p>{user?.Email}</p>
            <p onClick={() => logoutUser()}>Logout</p>
          </Dropdown>
        </>
      )}
    </MaxWidth>
  );
};

export default NavBar;
