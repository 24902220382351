import React from "react";
import { useUserContext } from "Authenticator";
import { fetcherWithToken } from "hooks/use-fetch-data";
import useSWR from "swr";
import { QuestionI } from "Pages/Test/types";
import { useParams } from "react-router-dom";
import { QuestionComponent } from "components";

export const ModalContent: React.FC<{
  moduleID?: number;
  questionNumber: number;
  student?: string;
}> = ({ moduleID, questionNumber, student }) => {
  const params = useParams();
  const userCtx = useUserContext();

  const { data, isLoading } = useSWR<QuestionI>(
    `/api/v1/auth/class/${params.classID}/test/${params.testID}/module/${moduleID}/question/${questionNumber}?student=${student}`,
    (url: string) => fetcherWithToken<QuestionI>(url, userCtx.token)
  );

  if (isLoading) return null;

  return (
    <>
      <div>
        {data?.question && data.module && (
          <QuestionComponent
            data={{ question: data?.question, module: data?.module, userAnswer: data?.userAnswer}}
          />
        )}
      </div>
    </>
  );
};
