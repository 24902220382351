import axios, { AxiosResponse } from 'axios';

export const fetcherWithToken = <T>(url: string, token: string): Promise<T> =>
  axios
    .get(`${process.env.REACT_APP_AUTH0_API_HOSTNAME}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res: AxiosResponse) => res.data as T);

export const postWithToken = async (
  url: string,
  token: string,
  body: Object
) => {
  const response = axios.post(
    `${process.env.REACT_APP_AUTH0_API_HOSTNAME}${url}`,
    { data: body },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response
};

export const updateWithToken = async (
  url: string,
  token: string,
  body: Object
) => {
  const response = axios.put(
    `${process.env.REACT_APP_AUTH0_API_HOSTNAME}${url}`,
    { data: body },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response
};
