import React from "react";

import { Label, Input, Switch } from "./styles";
import { Text } from "components/Text";

interface SwitchProps {
  checked: boolean;
  onChange: () => void;
  withText: string;
}

export const ToggleSwitch: React.FC<SwitchProps> = ({
  checked,
  onChange,
  withText
}) => {
  return (
    <Label>
      {withText && <Text>{withText}</Text>}
      <Input checked={checked} type="checkbox" onChange={onChange} />
      <Switch />
    </Label>
  );
};
