import styled from "styled-components";

export const Overlay = styled.div<{
  open?: boolean;
}>`

  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  ${({ open }) =>
    open &&
    `
    display: block;
    `}
`;

export const ModalContainer = styled.div<{ open: boolean, small?: boolean, medium?: boolean }>`
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 20px;
  width: 90%;
  height: 90%;
  overflow-y: auto;
  ${({ open }) =>
    open &&
    `
    display: block;
    `}

  ${({ medium }) =>
    medium &&
    `
    width: 75%;
    height: 75%;
    `}

  ${({ small }) =>
    small &&
    `
    width: 45%;
    height: 45%;
    `}
`;

