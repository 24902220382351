import React from "react";
import {
  NumberBox,
  QuestionNumberBox,
  QuestionTooltip,
  Key,
} from "./tooltipsstyles";
import { GrLocation } from "react-icons/gr";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { BsBookmarkFill } from "react-icons/bs";
import { Text } from "components/Text";
import { QuestionI } from "./types";
import { useNavigate, useParams } from "react-router-dom";

export const QuestionToolTip: React.FC<{
  data?: QuestionI;
  setClose?: () => void;
  showClose?: boolean
}> = ({ data, setClose,  showClose =  true }) => {
  const params = useParams();
  const navigate = useNavigate();

  const shouldDash = (question: QuestionI["question"]) => {
    if (
      question.UserAnswerChoice?.AnswerChoiceID ||
      question.UserAnswerChoice?.WriteInAnswer
    ) {
      return false;
    }

    return true;
  };

  const navigateAndClose = (questionNumber: number) => {
    navigate(
      `/class/${params.classID}/test/${params.testID}/module/${params.moduleId}/question/${questionNumber}`
    );

    setClose?.();
  };

  return (
    <>
      {showClose &&
      <AiOutlineCloseSquare
        color="black"
        size={20}
        onClick={() => setClose?.()}
        style={{ marginBottom: "5px", cursor: "pointer" }}
      />
      }
      <QuestionTooltip>
        <div className="key-container">
          <Key>
            <GrLocation />
            <Text size={18}>Current</Text>
          </Key>
          <Key>
            <NumberBox dashed>
              <Text color="white">"</Text>
            </NumberBox>
            <Text size={18} ml={5}>
              Unanswered
            </Text>
          </Key>
          <Key>
            <NumberBox dashed answered>
              <Text color="white">&nbsp;</Text>
            </NumberBox>
            <Text size={18} ml={5}>
              Answered
            </Text>
          </Key>
          <Key>
            <BsBookmarkFill color="red" />
            <Text size={18}>For Review</Text>
          </Key>
        </div>
        <QuestionNumberBox>
          {data?.allQuestions.map((q) => {
            const notAnswered = shouldDash(q);
            const saveForLater = q?.UserAnswerChoice?.SaveForLater;
            const current = q.QuestionNumber === Number(params.questionNumber);
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "min-content",
                  marginLeft: "10px",
                }}
              >
                {current && (
                  <GrLocation style={{ position: "absolute"  }} />
                )}
                {saveForLater && !current && (
                  <BsBookmarkFill
                    color="red"
                    style={{ position: "absolute" }}
                    size={11}
                  />
                )}
                <NumberBox
                  dashed
                  answered={!notAnswered}
                  onClick={() => navigateAndClose(q.QuestionNumber)}
                  size={18}
                  mt={10}
                >
                  {q.QuestionNumber}
                </NumberBox>
              </div>
            );
          })}
        </QuestionNumberBox>
      </QuestionTooltip>
    </>
  );
};
