import styled from "styled-components";
import { Props } from "./types";

export const DivComponent = styled.div<Props>`
   display: flex;

    ${({ alignItems }) =>
      alignItems &&
      `
    align-items: center;
    `}
    ${({ spaceBetween }) =>
      spaceBetween &&
      `
    justify-content: space-between;
    `}
    ${({ column }) =>
      column &&
      `
    flex-direction: column;
    `};

    ${({ justifyContent }) =>
      justifyContent &&
      `
    justify-content: center;
    `}

  ${({ mb }) =>
    mb &&
    `
    margin-bottom:  ${mb}px;
    `}

  ${({ mt }) =>
    mt &&
    `
    margin-top:  ${mt}px;
    `}

  ${({ fullHeight }) =>
    fullHeight &&
    `
    height: 100%;
    `}

  ${({  padding }) =>
    padding &&
    `
    padding:  ${padding};
    `}

  ${({  goRight }) =>
    goRight &&
    `
    margin-left: auto;
    `}

  ${({ mr }) =>
    mr &&
    `
    margin-right:  ${mr}px;
    `}

  ${({ ml }) =>
    ml &&
    `
    margin-right:  ${ml}px;
    `}
`;
