import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { KaTeXProvider, TokenProvider } from '../src/Authenticator';
import PrivateRoutes from '../src/routes';
import NavBar from 'components/Navbar';
import { ThemeProvider } from 'styled-components';
import { theme } from 'components/theme/theme';
import {Toaster} from 'react-hot-toast';

const App: React.FC = () => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      authorizationParams={{
        audience: 'https://dev-4s37z5ufez35sqko.us.auth0.com/api/v2/',
        /* eslint-disable-next-line camelcase */
        redirect_uri: window.location.origin,
      }}
    >
      <Router basename={'/'}>
        <Toaster/>
        <TokenProvider>
          <KaTeXProvider>
          <ThemeProvider theme={theme}>
            <NavBar />
            <PrivateRoutes />
          </ThemeProvider>
          </KaTeXProvider>
        </TokenProvider>
      </Router>
    </Auth0Provider>
  );
};

export default App;
