import styled from "styled-components";

export const Container = styled.div`
  margin: auto;
  border-radius: 10px;
  max-width: 1080px;
  background: ${({ theme }) => theme.colors.secondary1};
  margin-top: 24px;
`;

export const Header = styled.div`
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 24px;
  background: ${({ theme }) => theme.colors.primary2};
  color: ${({ theme }) => theme.colors.secondary1};
  margin-bottom: 10px;
`;
export const BodyContainer = styled.div`
  display: flex;
`;

export const Body = styled.div`
  flex: 2;
  padding: 24px;
  margin-bottom: 20px;
`;

export const BodyHeader = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin-left: 10px;
  }
`;

export const InstructionLogo = styled.img`
  width: 5%;
  height: 5%;
  border-radius: 50%;
`;

export const NumberBox = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #f5f5f5;
  width: 650px;
  flex-wrap: wrap;
`;
export const QuestionNumberBox = styled.span<{ selected?: boolean }>`
  border: 2px solid #f1f1f1;
  padding: 4px 4px;
  margin: 2px;
  color: ${({ theme }) => theme.colors.primary2};

  ${({ selected, theme }) =>
    selected &&
    `
    background: ${theme.colors.primary1};

    `}
`;
export const RightRail = styled.div`
  flex: 1;
  padding: 24px;
`;

export const RightRailBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${({ theme }) => `2px solid ${theme.colors.primary2}`};
  padding: 24px 18px;
  margin-bottom: 18px;

  svg {
    color: ${({ theme }) => theme.colors.primary1};
  }
`;

export const Untimed = styled.div`
  background: ${({ theme }) => theme.colors.grey1};
  color: ${({ theme }) => theme.colors.primary2};
  padding: 18px;
  border-radius: 10px;

  div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  svg {
    color: ${({ theme }) => theme.colors.primary1};
  }
`;
