import React from "react";
import { Container } from "./styles";

interface Props {
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  width?: number;
  name?: string;
  withLabelText?: string;
}

export const Input: React.FC<Props> = ({
  placeholder,
  onChange,
  value,
  width,
  name,
  withLabelText
}) => {
  return (
    <Container width={width}>
      {withLabelText &&
      <label>{withLabelText}</label>
      }
      <input
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        name={name}
      />
    </Container>
  );
};
