import styled from 'styled-components';

export const MaxWidth = styled.div`
  background: ${({ theme }) => theme.colors.secondary1};
`
export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  max-width: 1180px;
  margin: auto;
  background: ${({ theme }) => theme.colors.secondary1};
`;

export const Logo = styled.img`
  width: 10%;
  cursor: pointer;
`;

export const Circle = styled.div<{ open?: boolean }>`
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: ${({ open }) => (open ? 'none' : 'flex')};
  background: ${({ theme }) => theme.colors.primary1};

  p {
    color: ${({ theme }) => theme.colors.primary2};
    font-weight: 700;
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.secondary1};
  padding: 12px;
  z-index: 2;
  top: 72px;
  right: 14px;
  box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.09);

   & p:last-child {
     cursor: pointer;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-right: 5px;
  }
`
