import React, { useEffect, useState } from "react";
import { School, Student } from "./types";
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  getPaginationRowModel
} from "@tanstack/react-table";
import {
  RankingInfo,
  rankItem,
  compareItems
} from "@tanstack/match-sorter-utils";
import {
  IconContainer,
  InputContainer,
  Pagination,
  SectionContainerStudents,
  StepContainer,
  StudentHeader,
  UpdateStudentContainer
} from "./styles";
import { Button } from "components/Button";
import { Input } from "components/Input";
import { NewStudent } from "./SchoolOmboard";
import { FiUserMinus } from "react-icons/fi";
import { LuUserPlus2 } from "react-icons/lu";
import { Text } from "components/Text";
import { useUserContext } from "Authenticator";
import { postWithToken, updateWithToken } from "hooks/use-fetch-data";
import { Modal } from "components/Modal";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      style={{ padding: 10 }}
      {...props}
      value={value}
      onChange={e => setValue(e.target.value)}
    />
  );
}

export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const DEFAULT_USER: Student = {
  UserID: 0,
  Email: "",
  FirstName: "",
  LastName: "",
  Role: ""
};
export const Students: React.FC<{
  students?: Student[];
  school?: School;
  refreshDashboard: (schoolSlug?: School) => Promise<void>;
}> = ({ students = [], school, refreshDashboard }) => {
  const userCtx = useUserContext();
  const [data, setData] = React.useState(() => [...students]);
  const [createNewStudent, setCreateNewStudent] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<Student>(DEFAULT_USER);

  useEffect(() => {
    if (students.length != 0) {
      setData(students);
    }
  }, [students]);

  const [formData, setFormData] = useState<{
    students: NewStudent[];
  }>({
    students: [{ 1: { FirstName: "", LastName: "", Email: "" } }]
  });

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [globalFilter, setGlobalFilter] = React.useState("");

  const editUser = (user: Student) => {
    setModal(true);
    setSelectedStudent(user);
  };

  const columns = React.useMemo<ColumnDef<Student, any>[]>(
    () => [
      {
        header: "Student List",
        colSpan: 2,
        rowSpan: 2,
        columns: [
          {
            accessorFn: row => row.LastName,
            id: "LastName",
            header: "Last Name",
            cell: info => info.getValue(),
            filterFn: "fuzzy",
            sortingFn: fuzzySort
          },
          {
            accessorFn: row => row.FirstName,
            id: "FirstName",
            header: "First Name",
            cell: info => info.getValue(),
            filterFn: "fuzzy",
            sortingFn: fuzzySort
          },
          {
            accessorFn: row => row.Email,
            id: "email",
            header: "Email",
            cell: info => info.getValue(),
            filterFn: "fuzzy",
            sortingFn: fuzzySort
          },
          {
            header: "Edit",
            cell: cell => (
              <button onClick={() => editUser(cell.row.original)}>Edit</button>
            )
          }
        ]
      }
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter
    },
    state: {
      columnFilters,
      globalFilter
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getPaginationRowModel: getPaginationRowModel()
  });

  const studentChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
    index: number
  ) => {
    const updateStudents = formData.students.map(student => {
      const studentIndex = Number(Object.keys(student)[0]);

      if (studentIndex === index) {
        return {
          [index]: {
            ...student[index],
            [field]: e.target.value
          }
        };
      }

      return student;
    });

    setFormData({
      ...formData,
      students: updateStudents
    });
  };

  const minusStudent = () => {
    const allButLast = formData.students.slice(0, -1);

    setFormData({
      ...formData,
      students: [...allButLast]
    });
  };

  const addAnotherStudent = () => {
    const next =
      Number(Object.keys(formData.students[formData.students.length - 1])[0]) +
      1;

    setFormData({
      ...formData,
      students: [
        ...formData.students,
        { [next]: { FirstName: "", LastName: "", Email: "" } }
      ]
    });
  };

  const saveStudents = async () => {
    const students = formData.students.map(s => {
      const key = Number(Object.keys(s)[0]);
      return s[key];
    });

    const data = {
      users: students,
      schoolID: school?.SchoolID,
      role: "student"
    };
    const res = await postWithToken(
      `/api/v1/auth/admin/dashboard/save-users-to-school`,
      userCtx.token,
      {
        ...data
      }
    );

    if (res.status === 201) {
      refreshDashboard(school);
      setCreateNewStudent(false);
    }
  };

  const updateStudent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSelectedStudent(prevStudent => ({
      ...prevStudent,
      [name]: value
    }));
  };

  const submitUpdateStudent = async () => {
    const res = await updateWithToken(
      `/api/v1/auth/admin/update-user`,
      userCtx.token,
      selectedStudent
    );

    if (res.status === 201) {
      refreshDashboard(school);
      setModal(false);
      setSelectedStudent(DEFAULT_USER);
    }
  };

  return (
    <>
      <Modal open={modal} onClose={() => setModal(false)}>
        <Text size={32} center>
          Update Student
        </Text>
        <UpdateStudentContainer>
          <Input
            width={250}
            name="FirstName"
            value={selectedStudent.FirstName}
            onChange={e => updateStudent(e)}
          />
          <Input
            width={250}
            name="LastName"
            value={selectedStudent.LastName}
            onChange={e => updateStudent(e)}
          />
          <Input
            width={250}
            name="Email"
            value={selectedStudent.Email}
            onChange={e => updateStudent(e)}
          />
        </UpdateStudentContainer>
        <Button
          text="Update Student"
          onClick={() => submitUpdateStudent()}
          floatRight
        />
      </Modal>
      <SectionContainerStudents>
        {createNewStudent && (
          <>
            <StudentHeader>
              <Text size={24}>Create students for {school?.Name}</Text>
              <Button
                text="View Students"
                onClick={() => setCreateNewStudent(false)}
              />
            </StudentHeader>
            <StepContainer>
              {formData.students.map(student => {
                const key = Number(Object.keys(student)[0]);
                return (
                  <InputContainer key={key}>
                    <Input
                      width={250}
                      placeholder="First Name..."
                      value={student[key].FirstName}
                      onChange={e => studentChange(e, "FirstName", key)}
                    />
                    <Input
                      width={250}
                      placeholder="Last Name..."
                      value={student[key].LastName}
                      onChange={e => studentChange(e, "LastName", key)}
                    />
                    <Input
                      width={300}
                      placeholder="Email..."
                      value={student[key].Email}
                      onChange={e => studentChange(e, "Email", key)}
                    />
                  </InputContainer>
                );
              })}
              <IconContainer>
                <span>
                  <FiUserMinus size={30} onClick={minusStudent} />
                </span>
                <span onClick={addAnotherStudent}>
                  <LuUserPlus2 size={30} />
                </span>
              </IconContainer>
              <Button
                text="Create Students"
                onClick={saveStudents}
                floatRight
                mt={44}
              />
            </StepContainer>
          </>
        )}
        {!createNewStudent && (
          <>
            <StudentHeader>
              <DebouncedInput
                value={globalFilter ?? ""}
                onChange={value => setGlobalFilter(String(value))}
                placeholder="Search students..."
              />
              <Button
                text="Create New Students"
                onClick={() => setCreateNewStudent(true)}
              />
            </StudentHeader>
            <table>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <th key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination>
              <div>
                <button
                  onClick={() => table.setPageIndex(0)}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<<"}
                </button>
                <button
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {"<"}
                </button>
                <button
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {">"}
                </button>
                <button
                  onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                  disabled={!table.getCanNextPage()}
                >
                  {">>"}
                </button>
              </div>
              <div>
                <span style={{marginRight: "10px"}}>
                  <strong>
                    Page{" "}
                    {table.getState().pagination.pageIndex + 1} of{" "}
                    {table.getPageCount()}
                  </strong>
                </span>
                <select
                  value={table.getState().pagination.pageSize}
                  onChange={e => {
                    table.setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </Pagination>
          </>
        )}
      </SectionContainerStudents>
    </>
  );
};
