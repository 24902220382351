import {Text} from "components/Text";
import styled from "styled-components";

export const QuestionTooltip = styled.div`
  padding: 24px 0;

  .key-container {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
    padding: 10px 0;
  }
`;

export const NumberBox = styled(Text)<{ dashed?: boolean, answered?: boolean  }>`
  display: flex;
  justify-content: center;
  color: black;
  &:hover {
    cursor: pointer;
  }

  ${({ dashed, theme,  answered}) =>
    dashed &&
    `
      border: dashed 1px grey;
      background: ${answered && theme.colors.primary2};;
      color: ${answered && theme.colors.secondary1};
      padding: 5px 10px;
    `}
`;

export const Key = styled.div`
  display: flex;
  align-items: center;
`;

export const QuestionNumberBox = styled.div`
  display: flex;
  margin-top: 25px;
  flex-wrap: wrap;
`;
