import { Module } from "../types";

export interface GroupedData {
  [level: string]: {
    right: number;
    wrong: number;
    unanswered: number;
  };
}

export interface DomainData {
  domain: string;
  data: {
    right: number;
    wrong: number;
    unanswered: number;
  };
}

export const groupQuestionsByLevel = (questionsData: Module): GroupedData[] => {
  const result: GroupedData = {};

  questionsData.Questions?.forEach(question => {
    const { Level, AnsweredCorrectly, UserAnswer } = question;
    if (!result[Level]) {
      result[Level] = { right: 0, wrong: 0, unanswered: 0 };
    }
    if (AnsweredCorrectly === true) {
      result[Level].right++;
    } else if (AnsweredCorrectly === false && UserAnswer.trim() !== "") {
      result[Level].wrong++;
    } else {
      result[Level].unanswered++;
    }
  });

  // Convert result object to array of objects
  const formattedResult = Object.entries(result).map(([level, counts]) => ({
    [level]: counts
  }));

  return formattedResult;
};

export const groupQuestionsByDomain = (questionsData: Module): DomainData[] => {
  const result: {
    [domain: string]: { right: number; wrong: number; unanswered: number };
  } = {};

  questionsData.Questions.forEach(question => {
    const { Domain, AnsweredCorrectly, UserAnswer } = question;
    if (!result[Domain]) {
      result[Domain] = { right: 0, wrong: 0, unanswered: 0 };
    }
    if (AnsweredCorrectly === true) {
      result[Domain].right++;
    } else if (AnsweredCorrectly === false && UserAnswer.trim() !== "") {
      result[Domain].wrong++;
    } else {
      result[Domain].unanswered++;
    }
  });

  return Object.entries(result).map(([domain, data]) => ({ domain, data }));
};
