import styled from "styled-components";

export const Container = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    svg {
      cursor: pointer;
    }
  }

  thead tr {
    background: ${({ theme }) => theme.colors.primary1};
    color: #ffffff;
    text-align: left;
  }

  th,
  td {
    padding: 12px 15px;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }

  tbody tr:last-of-type {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.primary1}`};
  }
`
