import { Button } from "components/Button";
import React, { useState } from "react";
import {
  MainContainer,
  StepContainer,
  StepWrapper,
  StepStyle,
  StepsLabelContainer,
  StepLabel,
  ButtonsContainer,
  StepCount,
  CheckMark
} from "./styles";


export const Step: React.FC<{
  s: number;
  children?: React.ReactNode;
  next?: () => void;
}> = ({ s, children }) => {
  return <span key={s}>{children}</span>;
};

export const Stepper: React.FC<{ children?: React.ReactElement[]
  steps: { label: string; step: number }[];
}> = ({
  children,
  steps,
}) => {
  const [activeStep, setActiveStep] = useState(1);

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  const childToRender = children?.find(c => c.props.s === activeStep);
  const lastSubmit = childToRender?.props.next;

  return (
    <MainContainer>
      <StepContainer width={width}>
        {steps.map(({ step, label }) => (
          <StepWrapper key={step}>
            <StepStyle step={activeStep >= step ? "completed" : "incomplete"}>
              {activeStep > step ? (
                <CheckMark>L</CheckMark>
              ) : (
                <StepCount>{step}</StepCount>
              )}
            </StepStyle>
            <StepsLabelContainer>
              <StepLabel key={step}>{label}</StepLabel>
            </StepsLabelContainer>
          </StepWrapper>
        ))}
      </StepContainer>
      {childToRender}
      <ButtonsContainer>
        <Button
          text="Previous"
          onClick={prevStep}
          disabled={activeStep === 1}
        />
        <Button
          text={lastSubmit ? "Complete" : "Next"}
          onClick={lastSubmit ? () => lastSubmit() : nextStep}
          disabled={activeStep === totalSteps && !lastSubmit}
        />
      </ButtonsContainer>
    </MainContainer>
  );
};
