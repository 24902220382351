export interface DashboardI {
  TestID: number;
  Title: string;
  Modules?: {
    ModuleID: number;
    Title: string;
    Started: boolean;
    Order: number;
    UserModule: {
      Finished: boolean;
      Started: boolean;
    };
  }[];
}

export interface Root {
  ClassID: number;
  Name: string;
  Students: any;
  Teachers: any;
  Tests: Test[];
  SchoolID: number;
}

export interface Test {
  TestID: number;
  Title: string;
  ProductNumber: number;
  Modules: Module[];
  SavedProgress?: SavedProgress
}

export interface SavedProgress {
  ModuleID: number
  QuestionNumber: number
  TestID: number
  UserID: number
}

export interface Module {
  ModuleID: number;
  Title: string;
  Instructions: string;
  Started: boolean;
  Time: number;
  TestID: number;
  Order: number;
  Questions: any;
  UserModule: any;
}

export const GRAD_YEARS = [
  2024,
  2025,
  2026,
  2027,
  2028,
  2029,
  2030,
  2031,
  2032,
  2033,
  2034,
  2035,
  2036
];
