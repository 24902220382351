export interface ITheme {
  colors: {
    primary1: string;
    primary2: string;
    secondary1: string;
    grey1: string;
  };
  mediaQueries: {
    tablet: string;
    mobileAndTablet: string;
    desktop: string;
    largeDesktop: string;
  };
}

export const theme: ITheme = {
  colors: {
    primary1: '#FBB23A',
    primary2: '#154471',
    secondary1: '#FFFFFF',
    grey1: '#f5f5f5',
  },
  mediaQueries: {
    tablet: 'only screen and (min-width: 768px)',
    mobileAndTablet: 'only screen and (max-width: 1023px)',
    desktop: 'only screen and (min-width: 1024px)',
    largeDesktop: 'only screen and (min-width: 1176px)',
  },
};
