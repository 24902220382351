import styled from "styled-components";
import { Props } from './types'

export const ButtonComponent = styled.button<Props>`
  background: ${({ theme }) => theme.colors.primary2};
  border: ${({ theme }) => theme.colors.primary2};
  color: ${({ theme }) => theme.colors.secondary1};
  font-size: 14px;
  padding: 12px 16px;
  border-radius: 100px;
  min-width: 100px;

  &:hover {
    cursor: pointer;
  }
  ${({ floatRight }) =>
    floatRight &&
    `
      display: block;
    margin-left: auto;
  margin-right: 0;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    `
    width: 100%;
    `}

  ${({ mb }) =>
    mb &&
    `
    margin-bottom:  ${mb}px;
    `}

  ${({ ml }) =>
    ml &&
    `
    margin-left:  ${ml}px;
    `}

  ${({ mr }) =>
    mr &&
    `
    margin-right:  ${mr}px;
    `}

  ${({ mt }) =>
    mt &&
    `
    margin-top:  ${mt}px;
    `}

  ${({ color }) =>
    color &&
    `
    background: ${color};
    `}

  ${({ invisible }) =>
    invisible &&
    `
    visibility: hidden;
    `}

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed !important;
    background-color: rgb(217, 217, 217) !important;
    border-bottom-color: rgb(217, 217, 217) !important;
    `}
`;
